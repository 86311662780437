<template>
  <b-container class="py-5 px-4 px-md-5 container_alerts">
    <b-row>
      <b-col cols="12">
        <h2 class="fw-bold">Alert definitions</h2>
      </b-col>
      <b-col cols="12" class="text-end">
        <b-button variant="secondary" class="mb-4" :to="{ name: 'alerts.add' }">Add alert definition</b-button>
      </b-col>
      <b-col cols="12">
        <b-table hover :items="items" :fields="fields" :busy="load" responsive
          tbody-tr-class="transition-tr" class="table-alerts" table-variant="light">
        <template #table-busy>
          <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(status)="row">
          <b-form-checkbox v-if="row.item.status" v-model="row.item.status"></b-form-checkbox>
          <b-form-checkbox v-else v-model="row.item.status"></b-form-checkbox>
        </template>
        <template #cell(actions)="row">
          <b-button variant="default" class="py-0">
            <vue-fontawesome icon="edit" size="1.5" color="#DBDBDB"/>
          </b-button>
          <b-button variant="default" class="py-0">
            <vue-fontawesome icon="trash" size="1.5" color="#DBDBDB"/>
          </b-button>
        </template>
      </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        items: [
          { name: 'Error in API DGO', status: true },
          { name: 'Error in API MCSS', status: false },
          { name: 'Error in API DOCS', status: true },
        ],
        fields: [
          { key: 'name', label: 'Name' },
          { key: 'status', label: 'Status' },
          { key: 'actions', label: 'Actions'}
        ],
        load: false
      }
    }
  }
</script>
